import { Action, configureStore, ThunkAction, AnyAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import {
  authReducer,
  dashboardReducer,
  paymentsReducer,
  outboundTransactionsReducer,
  branchesReducer,
  globalReducer,
  remitterReducer,
  thirdPartyReducer,
  beneficiaryReducer,
  transactionReducer,
  uiReducer,
  paytumiReducer
} from "./slices";

const reducers = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  payments: paymentsReducer,
  outbound: outboundTransactionsReducer,
  branches: branchesReducer,
  global: globalReducer,
  remitter: remitterReducer,
  thirdParty: thirdPartyReducer,
  beneficiary: beneficiaryReducer,
  transaction: transactionReducer,
  ui: uiReducer,
  paytumi: paytumiReducer,
});

const persistConfig = {
  key: process.env.NODE_ENV !== "production" ? "root:afx:test" : "root:afx:live",
  version: 1,
  storage,
};

// Handle Logout to clear entire state
const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === "auth/clearForm") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, reducerProxy);
// const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
