import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { formatErrors, handleAPI } from "@redux/services/APIHandler";

export type PaytumiState = {
  loading: boolean;
  searchMemberLoading: boolean;
  message: string | null;
  errors: string[];
  searchMemberErrors: string[];
  merchants: any[];
  currencies: any[];
  payment_types: any[];
  charges: any;
  transactionData: any;
  members: any;
  paytumiTransactions?: any;
};

const initialState: PaytumiState = {
  loading: false,
  searchMemberLoading: false,
  message: null,
  errors: [],
  searchMemberErrors: [],
  merchants: [],
  currencies: [],
  payment_types: [],
  charges: null,
  transactionData: null,
  members: null,
  paytumiTransactions: null,
};

export const getMerchants = handleAPI("paytumi/getMerchants", "get", `paytumi/merchant/list`);
export const getCharges = handleAPI("paytumi/getCharges", "post", `paytumi/get-charges`);
export const getCurrencies = handleAPI("paytumi/getCurrencies", "get", `paytumi/currencies`);
export const getPaymentTypes = handleAPI("paytumi/getPaymentTypes", "get", `paytumi/payment_types`);
export const addTransaction = handleAPI("paytumi/addTransaction", "post", `paytumi/add-transaction`);
export const searchMember = handleAPI("paytumi/searchMember", "post", `paytumi/search-member`);
export const getPaytumiTransaction = handleAPI("paytumi/transactions", "post", `reports/paytumi`);

export const paytumiSlice = createSlice({
  name: "paytumi",
  initialState,
  reducers: {
    clearCharges: (state) => {
      state.loading = false;
      state.charges = null;
    },
    clearMembers: (state) => {
      state.loading = false;
      state.members = null;
    },
    clearPaytumi: (state) => {
      state.loading = false;
      state.errors = initialState.errors;
      state.charges = initialState.charges;
      state.transactionData = initialState.transactionData;
      state.paytumiTransactions = initialState.paytumiTransactions;
    },
  },
  extraReducers: (builder) => {
    // Get Merchants
    builder.addCase(getMerchants.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.merchants = [];
      state.message = null;
    });
    builder.addCase(getMerchants.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.merchants = payload?.response ?? null;
    });
    builder.addCase(getMerchants.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.merchants = [];
      state.errors = formatErrors(payload);
    });

    // Get Charges
    builder.addCase(getCharges.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.charges = null;
      state.message = null;
    });
    builder.addCase(getCharges.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.charges = payload?.response ?? null;
    });
    builder.addCase(getCharges.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.charges = null;
      state.errors = formatErrors(payload);
    });

    // Get Currencies
    builder.addCase(getCurrencies.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.currencies = [];
      state.message = null;
    });
    builder.addCase(getCurrencies.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.currencies = payload?.response ?? null;
    });
    builder.addCase(getCurrencies.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.currencies = [];
      state.errors = formatErrors(payload);
    });

    // Get Payment Types
    builder.addCase(getPaymentTypes.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.payment_types = [];
      state.message = null;
    });
    builder.addCase(getPaymentTypes.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.payment_types = payload?.response ?? null;
    });
    builder.addCase(getPaymentTypes.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.payment_types = [];
      state.errors = formatErrors(payload);
    });

    // Add Transaction
    builder.addCase(addTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.transactionData = null;
    });
    builder.addCase(addTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.transactionData = payload?.response ?? null;
    });
    builder.addCase(addTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.transactionData = null;
      state.errors = formatErrors(payload);
    });

    // searchMember
    builder.addCase(searchMember.pending, (state) => {
      state.searchMemberLoading = true;
      state.searchMemberErrors = [];
      state.members = null;
    });
    builder.addCase(searchMember.fulfilled, (state, { payload }: any) => {
      state.searchMemberLoading = false;
      state.searchMemberErrors = [];
      state.members = payload?.response ?? null;
    });
    builder.addCase(searchMember.rejected, (state, { payload }: any) => {
      state.searchMemberLoading = false;
      state.members = null;
      state.searchMemberErrors = formatErrors(payload);
    });

    // paytumi search transaction results
    builder.addCase(getPaytumiTransaction.pending, (state) => {
      state.loading = true;
      state.errors = [];
      state.paytumiTransactions = null;
    });
    builder.addCase(getPaytumiTransaction.fulfilled, (state, { payload }: any) => {
      state.loading = false;
      state.errors = [];
      state.paytumiTransactions = payload?.response ?? null;
    });
    builder.addCase(getPaytumiTransaction.rejected, (state, { payload }: any) => {
      state.loading = false;
      state.paytumiTransactions = null;
      state.errors = formatErrors(payload);
    });
  },
});

//Export actions
export const { clearCharges, clearPaytumi, clearMembers } = paytumiSlice.actions;

// shortcuts
// export const getBranch = (state: RootState) => state.dashboard.branch;

export default paytumiSlice.reducer;
