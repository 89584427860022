import React, { useState } from "react";
import { motion } from "framer-motion";
import { setModalContent } from "@redux/slices/globalSlice";
import { useAppDispatch } from "@redux/hooks";
interface IProps {
  title: string;
  show: boolean;
  children: React.ReactNode;
  onClose: any;
  rounded?: boolean;
  full?: boolean;
  animated?: boolean;
  width?: string;
  backgroundClose?: boolean;
}

const Modal = ({ title, show, children, onClose, rounded, full, animated, width, backgroundClose }: IProps) => {
  const dispatch = useAppDispatch();
  return (
    <>
      {show ? (
        <>
          <motion.div
            initial={animated ? { y: "100%" } : {}}
            animate={animated ? { y: 0 } : {}}
            transition={animated ? { ease: "easeOut", duration: 0.5 } : {}}
            className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={() => {
              if(backgroundClose){
                dispatch(setModalContent(null));
                onClose();
              }
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={`relative my-auto mx-auto ${full ? "w-full h-full" : "w-auto"} ${width ?? ""} z-100`}
            >
              <div className={`relative bg-white ${rounded ? "rounded-lg" : ""} shadow dark:bg-gray-700`}>
                <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                  <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mx-2">{title}</h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-toggle="defaultModal"
                    onClick={() => {
                      dispatch(setModalContent(null));
                      onClose();
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="p-6 space-y-6">{children}</div>
              </div>
            </div>
          </motion.div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
